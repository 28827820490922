import { Image } from "./image";

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            <div className='col-sm-2 col-md-2 col-lg-12'>
              <Image
                title={'Beesoftware'}
                largeImage={'img/header/logo.png'}
                smallImage={'img/header/logo.png'}
                imageStyle={{minWidth: '100', height: '60'}}
              />
            </div>
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
                <a href='#partners' className='page-scroll'>
                  PARTNER
                </a>
            </li>
            <li>
              <a href='#features' className='page-scroll'>
                Destacadas
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                Nosotros
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Servicios
              </a>
            </li>
            {
              /*
              <li>
                <a href='#portfolio' className='page-scroll'>
                  Galería
                </a>
              </li>
              */
            }
            {
              /*
              <li>
                <a href='#testimonials' className='page-scroll'>
                  Testimonios
                </a>
              </li>
              */
            }
            <li>
              <a href='#team' className='page-scroll'>
                Registro Obra Blanca
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
