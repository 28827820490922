export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Registro a la experiencia</h2>
          <p>
            ¿Quieres registrarte para participar en la experiencia Obra Blanca 2022? 
          </p>
        </div>
        {
          /*
          <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        */
        }
        <div id='row'>
          <a href="https://obrablanca.beesoftware.dev" rel="noreferrer" target="_blank" className='btn btn-custom btn-lg' style={{marginBottom: '60px'}}>
            !Haz click aquí!
          </a>
        </div>

        <div id='row'>
           <div className='col-md-6 col-sm-6 team' style={{marginBottom: '40px'}}>
            <a href="https://beesoftware.dev" rel="noreferrer" target="_blank">
              <img src={'img/banner.jpg'} alt='Banner sobre el registro a la experiencia' className='team-img' />
            </a>
          </div>
          <div className='col-md-6 col-sm-6 team'  style={{marginBottom: '40px'}}>
            <a href="https://porcelanite.com.mx/" rel="noreferrer" target="_blank">
              <img src={'img/percelannite.png'} alt='Banner sobre el registro a la experiencia' className='team-img' />
            </a>
          </div>
        </div>
      
      </div>
    </div>
  )
}
