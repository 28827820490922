export const Image = ({ title, largeImage, smallImage, imageStyle={} }) => {
  return (
    <div className='portfolio-item'>
      <div className='hover-bg'>
        {' '}
        <a
          href={largeImage}
          title={title}
          data-lightbox-gallery='gallery1'
        >
          {
            title && (<div className='hover-text'><h4>{title}</h4></div>)
          }
          <img
            src={smallImage}
            className='img-responsive'
            alt={title}
            style={imageStyle}
          />{' '}
        </a>{' '}
      </div>
    </div>
  )
}