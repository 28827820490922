import { useState } from 'react';
import  {ToastContainer,  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  name: '',
  email: '',
  company: '',
  role: '',
  message: '',
  phone: '',
  service: '',
}
export const Contact = (props) => {
  const [{ name, email, company, role, message, phone, service, }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    toast.info("Tu mensaje esta siendo enviado...", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    fetch('https://obrabrancaapi.beesoftware.dev/api/v1/system/email/send-contact-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email, company, role, message, phone, service, messageType: 'contact'})
    })
    .then(res => res.json())
    .then(result => {
      console.log('result', result); // JSON data parsed by `data.json()` call
      clearState();
      toast.success("Mensaje enviado!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }, error => {
      console.log('error', error); // JSON data parsed by `data.json()` call
      toast.error("Se ha generado un error, favor de intentar de nuevo!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    });
  }
  return (
    <div>
      <ToastContainer />
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>¿Te interesa un proyecto?</h2>
                <p>
                Complete el siguiente formulario para enviarnos un correo electrónico
                y nos pondremos en contacto con usted lo antes posible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nombre*'
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email*'
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='company'
                        name='company'
                        className='form-control'
                        placeholder='Empresa que represento*'
                        required
                        onChange={handleChange}
                        value={company}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='role'
                        name='role'
                        className='form-control'
                        placeholder='Puesto*'
                        required
                        onChange={handleChange}
                        value={role}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Telefono'
                        onChange={handleChange}
                        value={phone}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='service'
                        name='service'
                        className='form-control'
                        placeholder='Servicio de interes'
                        onChange={handleChange}
                        value={service}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div className='section-title'> <p> * Campos obligatorios a llenar.</p> </div>
                <div className='section-title'> 
                  <p>
                    Al enviar el formulario acepta los terminos & condiciones de uso,
                    así como las politicas de privacidad expresas en el siguiente
                    {"  "}<a href="https://beesoftware.dev/docs/legal.pdf" target='_blank' style={{color: 'inherit', textDecoration: 'underline'}}>documento</a>.
                  </p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar mensaje
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contacto </h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Dirección
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefono
                </span>{' '}
                {props.data ? <a href={`tel:${props.data.phone}`} style={{color: 'white'}}>{props.data.phone}</a> : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? <a href={`mailto:${props.data.email}`} style={{color: 'white'}}>{props.data.email}</a> : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  {
                    /*
                    <li>
                      <a href={props.data ? props.data.twitter : '/'}>
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    */
                  }
    
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 All Rights Reserved. Design by{' '}
            <a href='http://www.beesoftware.mx' rel='nofollow'>
              Beesoftware
            </a>
          </p>
          <p>
            {"  "}<a href="https://beesoftware.dev/docs/legal.pdf" target='_blank' rel='nofollow'>Terminos & Condiciones de uso</a> |
            {"  "}<a href="https://beesoftware.dev/docs/legal.pdf" target='_blank' rel='nofollow'>Aviso de privacidad</a>
          </p>
        </div>
      </div>
    </div>
  )
}
