import { Image } from "./image";

export const Partners = (props) => {
	return (
	  <div id='partners' className='text-center'>
		<div className='container'>
		  <div className='col-md-10 col-md-offset-1 section-title'>
			<h2>Nuestro Partner</h2>
		  </div>
		  <div className='row'>
			{props.data
			  ? props.data.map((d, i) => (
				  <div key={`${d.title}-${i}`} className='col-xs-12 col-md-6'>
					<a href={`${d.url}`}>
					{' '}
					<img
						src={d.img}
						alt=''
						style={{width: '50%', maxWidth: '320px'}}
					/>{' '}
					</a>

				  </div>
				))
			  : 'Loading...'}
		  </div>
		</div>
	  </div>
	)
  }
  